<script setup lang="ts">
import type { Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'

const props = defineProps({
  devise: {
    type: Object,
    required: true,
  },
  mode: {
    type: String,
    default: 'glue',
  },
})

defineSlice({
  description: 'List of featured offers to lead user to specials',
  fields: {
    title: { type: 'text', label: 'Title' },
    description: { type: 'textarea', label: 'Description' },
    bigDescription: { type: 'checkbox', label: 'Big Description?' },
    categoryId: { type: 'select', label: 'Category', allowNull: false, options: [] },
    orderby: { type: 'select', label: 'Order By', options: { end_date: 'End Date', priority: 'Priority' } },
  },
  name: { label: 'Deals', group: 'Glue' },
  templates: [{ label: 'Deals', fields: { bigDescription: { value: true }, categoryId: { value: '54' }, description: { value: 'We\u2019ve put a few special offers together to make your stay with us even better.' }, orderby: { value: 'priority' }, title: { value: 'Make your trip extra special' } } }],
})

const swiper: Ref<SwiperType | null> = ref(null)
const pageSpecials: Ref<AtlantisSpecial[]> = ref([])

const firstFiveSpecials: ComputedRef<AtlantisSpecial[]> = computed(() => pageSpecials.value.slice(0, 5))
const activeIndex = computed(() => {
  if (swiper.value)
    return swiper.value.activeIndex - 1

  return 1
})

async function requestSpecialsCategories() {
  const categoryId = props.devise.fields.categoryId?.value ?? 35
  const orderby = props.devise.fields.orderby?.value ?? 'priority'

  $voixFetch(`/api/devise/page-specials/all/?category_id=${categoryId}&orderby=${orderby}&`).then((response) => {
    pageSpecials.value = response.data
  })
}

onMounted(() => {
  requestSpecialsCategories()
})

function onSwiper(swiperInstance: SwiperType) {
  swiper.value = swiperInstance
}

function next() {
  if (swiper.value)
    swiper.value.slideNext()
}

function prev() {
  if (swiper.value)
    swiper.value.slidePrev()
}

function goToSlide(index: number) {
  if (swiper.value)
    swiper.value.slideTo(index)
}
</script>

<template>
  <div data-testid="deals-slice" class="pb-20 flex flex-col lg:flex-row max-w-full">
    <div
      class="px-8 lg:px-0 lg:pt-32 mb-10 lg:mb-0"
      :class="{
        'w-full lg:w-1/4': !devise.fields.bigDescription.checked,
        'w-full lg:w-1/3': devise.fields.bigDescription.checked,
      }"
    >
      <div class="w-full lg:w-3/4 mx-auto">
        <div
          class="mb-3 break-words"
          :class="{
            'max-w-120 headline': mode === 'glue',
            'text-glueblue-600 font-sans opacity-80 font-black uppercase text-3xl xl:text-[3.75rem] xl:leading-[65px]':
              mode === 'summer',
          }"
        >
          {{ devise.fields.title.text }}
        </div>
        <div
          class="mb-10"
          :class="{
            'max-w-64': !devise.fields.bigDescription.checked,
            'max-w-96': devise.fields.bigDescription.checked,
            'body-lg ': mode === 'glue',
            'text-glueblue-600 opacity-80 text-lg font-sans2 text-normal':
              mode === 'summer',
          }"
        >
          {{ devise.fields.description.text }}
        </div>
        <a
          class="glue-btn"
          :class="{
            ' text-gray-800 text-xs tracking-widest p-3 px-16 border border-gray-500':
              mode === 'glue',
            ' text-glueblue-600 p-3 px-16 border border-glueblue-600':
              mode === 'summer',
          }"
          href="/specials"
        >
          See All Specials
        </a>
      </div>
    </div>

    <div
      v-if="pageSpecials && pageSpecials.length > 2"
      class="relative clipping-slider overflow-hidden"
      :class="{
        'lg:w-3/4 ': !devise.fields.bigDescription.checked,
        'lg:w-2/3': devise.fields.bigDescription.checked,
      }"
    >
      <Swiper
        slides-per-view="auto"
        :space-between="10"
        :loop="true"
        :centered-slides="false"
        @swiper="onSwiper"
      >
        <SwiperSlide
          v-for="special in firstFiveSpecials"
          :key="special.id"
          class="flex"
        >
          <GlueSpecialsSpecialsLayoutsSmallSpecial
            :key="special.id"
            data-testid="glue-specials-layout-small-special"
            class="w-full"
            style="max-width: 359px"
            :mode="mode"
            :special="special"
          />
        </SwiperSlide>

        <SwiperSlide class="flex">
          <div
            class="relative w-96 h-full bg-glueblue-700 hotel-theme-bg hotel-theme-fg text-white p-8"
          >
            <div
              class="uppercase font-sans font-light leading-tight text-white text-2xl mb-4 tracking-widest"
            >
              See All Specials
            </div>
            <a
              href="`/specials`"
              class="absolute bottom-0 mb-16 glue-btn text-white text-xs tracking-widest p-3 pt-5 px-16 border border-white"
            >
              See More
            </a>
          </div>
        </SwiperSlide>
      </Swiper>

      <div class="flex justify-between mx-4 lg:ml-0">
        <div class="flex items-center space-x-3 mt-4">
          <button
            :class="{
              'slider-button border-gray-500': mode === 'glue',
              'w-10 h-10 flex justify-center items-center rounded-full border border-glueblue-600 text-glueblue-600 transition-colors duration-500 hover:bg-white':
                mode === 'summer',
            }"
            @click="prev"
          >
            <svg
              class="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
          <button
            :class="{
              'slider-button border-gray-500': mode === 'glue',
              'w-10 h-10 flex justify-center items-center rounded-full border border-glueblue-600 text-glueblue-600 transition-colors duration-500 hover:bg-white':
                mode === 'summer',
            }"
            @click="next"
          >
            <svg
              class="w-7 h-7 transform rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>

        <div class="flex space-x-3 mt-6">
          <button
            v-for="(slide, n) in firstFiveSpecials"
            :key="n"
            class="h-1.5 w-1.5 rounded-full transistion-colors duration-500"
            :class="{
              'bg-gray-500': n !== activeIndex % 5,
              'bg-white': n === activeIndex % 5,
            }"
            @click="goToSlide(n)"
          />
        </div>
      </div>
    </div>
    <div v-else class="grid grid-cols-2 gap-8">
      <GlueSpecialsSpecialsLayoutsSmallSpecial
        v-for="special in pageSpecials"
        :key="special.id"
        class="w-96"
        :special="special"
      />
    </div>
  </div>
</template>

<style scoped>
.swiper-slide {
  width: auto;
  height: auto;
}

.swiper-wrapper {
  @apply items-stretch;
}
</style>
